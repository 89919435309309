import React, { useState } from 'react'
import Fade from "react-reveal/Fade"

import Layout from "../layouts/App"
import SEO from "../components/seo"




export default ({pageContext}) => {
    let country = 'ja'
    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        country = 'ko'
    }
return(
    <Layout>
        <SEO title="Contact Us"/>
        <article className="contact">
            <section className="contact__Header">
                <Fade bottom distance={`100px`}>
                    <div className="map_iframe"><iframe src={pageContext.google_map.gmlink} frameBorder="0" title="google map" allowFullScreen=""></iframe></div>
                </Fade>
                <Fade bottom distance={`100px`}>
                <div className="contact_adress">
                    {/* {pageContext.contactAddresses.map(address => {
                        return( */}
                            <div className="address_wrapper">
                            <address>
                                <pre dangerouslySetInnerHTML={{ __html: pageContext.contactAddresses.address_1.replace(/\r\n/g, '<br />') }} />
                            </address>
                            </div>
                        {/* )
                    })
                    } */}
                </div>
                </Fade>
            </section>
            <section className="contact__list container-fluid-custom-only">
                <div className="row">
                    {pageContext.contactPersons.map(contactPerson => {
                        return(
                            <Fade bottom distance={`100px`}>
                            <div className="col-12 col-lg-3 contact__list__item">
                                <h2>{contactPerson.designation}</h2>
                                <h3>{contactPerson.name}</h3>
                                <a href={`mailto:${contactPerson.email}`} target="_blank" rel="noopener noreferrer">{contactPerson.email}</a>
                            </div>
                            </Fade>
                        )
                    })
                    }
                </div>
            </section>
        </article>
    </Layout>
)}